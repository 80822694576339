import React, { useState, useContext, useEffect } from "react";
import { DeviceContext } from "../../contexts";
import { Nav } from "../../components/nav";
import { ReactComponent as SBARLogo } from "../../assets/svg/sbar-logo.svg";
import { ReactComponent as MenuIcon } from "../../assets/svg/menu-icon.svg";

export const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isNotDesktop, setIsNotDesktop] = useState(false);
  const deviceType = useContext(DeviceContext);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setIsNotDesktop(deviceType === "mobile" || deviceType === "tablet");
  }, [deviceType]);

  useEffect(() => {
    const body = document.querySelector("body");
    if (isOpen && isNotDesktop) {
      body.style.overflow = "hidden";
    }
    if (!isOpen && isNotDesktop) {
      body.style.overflow = "auto";
    }
  }, [isOpen, isNotDesktop]);

  return (
    <header>
      <div className="logo">
        <picture className={`logo-img ${isOpen ? "logo-img--active" : ""}`}>
          <a href="/">
            <SBARLogo />
          </a>
        </picture>
        {isNotDesktop && (
          <button
            onClick={handleClick}
            className={`navbar-button-mobile ${
              isOpen ? "navbar-button-mobile--active" : ""
            }`}
          >
            <MenuIcon />
          </button>
        )}
      </div>
      <Nav top handleClick={handleClick} active={isNotDesktop && isOpen} />
    </header>
  );
};
