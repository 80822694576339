import React from "react";

export const Loading = () => (
  <>
    <div className="loading-container">
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <p className="loading--message">Laddar upp videon</p>
    </div>
  </>
);
