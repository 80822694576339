import React, { useContext, useState, useEffect } from "react";
import { getVideoThumbnail } from "../../api/methods/getVideoThumbnail";
import { ActiveStoryContext } from "../../contexts/active-story-context";
import { DeviceContext } from "../../contexts/device-context";
import {
  StoriesContext,
  getNumberInGroup,
} from "../../contexts/stories-context";
import { Loading } from "../loading";
import { AudioStory } from "./audio";
import { TextStory } from "./text";
import { VideoStory } from "./video";

const typeToComponentMap = {
  audio: AudioStory,
  text: TextStory,
  video: VideoStory,
};

const Story = ({ story }) => {
  const { setActiveStory } = useContext(ActiveStoryContext);
  const [renderStory, setRenderStory] = useState({ ...story });

  useEffect(() => {
    if (renderStory.media_type === "video" && !renderStory?.thumbnail) {
      const videoId = renderStory.media_url.replace("https://vimeo.com/", "");
      getVideoThumbnail(videoId)
        .then((res) => {
          const thumbnail = res.data[0].thumbnail_large;
          setRenderStory({
            ...renderStory,
            thumbnail,
          });
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [renderStory]);
  return (
    <div
      onClick={() => setActiveStory(story)}
      className={`story-card  ${
        renderStory?.title ? "" : "story-card-with-title"
      }`}
    >
      {renderStory?.thumbnail && (
        <div className="story-card--image">
          <img src={renderStory?.thumbnail} alt="thumbnail" />
        </div>
      )}
      <h3 className="story-card--title">{renderStory?.title}</h3>
    </div>
  );
};

const StoriesGroup = ({ subgroup, bigRow, remainderRow }) => {
  const { activeStory, setActiveStory } = useContext(ActiveStoryContext);
  const [isActive, setActive] = useState(false);
  useEffect(() => {
    if (subgroup?.length > 0 && activeStory) {
      setActive(subgroup.some((story) => story.id === activeStory?.id));
    }
  }, [activeStory, subgroup]);

  return (
    <div className="stories-group--container">
      <div
        className={`stories-group--${
          bigRow && !remainderRow ? "row--big" : "row"
        } ${remainderRow ? "stories-group--remainder" : ""}`}
      >
        {subgroup.map((story) => (
          <Story key={story.id} story={story} />
        ))}
      </div>

      <div className="story-active">
        {isActive &&
          [activeStory].map((story) => {
            const ActiveStory = typeToComponentMap[activeStory.media_type];
            return (
              <div key={story.id} className="story-active-container">
                <ActiveStory
                  handleClose={() => {
                    setActiveStory(null);
                    setActive(false);
                  }}
                  story={story}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export const Stories = () => {
  const { stories, isLoading } = useContext(StoriesContext);
  const deviceType = useContext(DeviceContext);
  const [isBigScreen, setIsBigScreen] = useState(false);

  useEffect(() => {
    setIsBigScreen(
      deviceType === "medium-screen" || deviceType === "big-screen"
    );
  }, [deviceType]);

  if (isLoading) {
    return (
      <div style={{ position: "relative" }}>
        <Loading />
      </div>
    );
  }

  return (
    <div className="stories--section">
      {stories.map((subgroup, i) => (
        <StoriesGroup
          key={i}
          bigRow={isBigScreen && (i + 1) % 2 === 0}
          remainderRow={subgroup.length < getNumberInGroup(deviceType)}
          subgroup={subgroup}
        />
      ))}
    </div>
  );
};
