import React from "react";
import { ReactComponent as CloseSVG } from "../../../assets/svg/close.svg";

export const TextStory = ({ story, handleClose }) => (
  <div className="story-card-container story-card-container--text">
    <div className="story-card-content story-card-content--text">
      <div className="story-card-content-text story-card-content-text--situation">
        <h3>situation</h3>
        {story.situation}
      </div>
      <div className="story-card-content-text story-card-content-text--background">
        <h3>bakgrund</h3>
        {story.background}
      </div>
      <div className="story-card-content-text story-card-content-text--assessment">
        <h3>aktuellt</h3>
        {story.assessment}
      </div>
      <div className="story-card-content-text story-card-content-text--recommendation">
        <h3>rekommendation</h3>
        {story.recommendation}
      </div>
    </div>
    <div className="story-card-content--meta story-card-content--meta--text">
      <div className="story-card-content--flexrow">
        <div className="story-card-content--type">{story.media_type}</div>
        <div className="story-card-content--close">
          <CloseSVG onClick={handleClose} />
        </div>
      </div>
      {story?.title && (
        <h3 className="story-card-content--title">{story.title}</h3>
      )}
      <div className="story-card-content--name">{story.fullname}</div>
    </div>
  </div>
);
