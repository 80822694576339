import React from "react";
import { ReactComponent as CloseSVG } from "../../../assets/svg/close.svg";

export const AudioStory = ({ story, handleClose }) => {
  return (
    <div className="story-card-container">
      <div className="story-card-content story-card-content--audio">
        <audio controls src={story.media_url} />
      </div>
      <div className="story-card-content--meta">
        <div className="story-card-content--flexrow">
          <div className="story-card-content--type">{story.media_type}</div>
          <div className="story-card-content--close">
            <CloseSVG onClick={handleClose} />
          </div>
        </div>
        {story?.title && (
          <h3 className="story-card-content--title">{story.title}</h3>
        )}
        <div className="story-card-content--name">{story.fullname}</div>
      </div>
    </div>
  );
};
