import React from "react";
import { Intro } from "../components/intro";
import { NursingConference } from "../components/intro/nursingConference";
import { DeviceContext } from "../contexts/device-context";

export const NursingConferenceView = () => (
  <DeviceContext.Consumer>
    {(deviceType) => (
      <Intro position={deviceType === "mobile" ? "left" : "center"}>
        <NursingConference />
      </Intro>
    )}
  </DeviceContext.Consumer>
);
