import React from "react";
import { Nav } from "../../components/nav";
import { ReactComponent as SBARLogo } from "../../assets/svg/sbar-logo.svg";

export const Footer = () => (
  <footer className="footer">
    <div className="footer-container-nav">
      <div className="logo">
        <picture className="logo--img">
          <SBARLogo />
        </picture>
      </div>
      <Nav active />
    </div>

    <div className="footer-container-text">
      <p className="footer-text--mobile">
        Detta är ett initiativ av vård och omsorgsarbetare för vård och
        omsorgsarbetare. Vi vill dela erfarenheter och idéer med varandra om vår
        arbetssituation och kämpa för en värdig vård för alla som inte gör oss
        sjuka!
      </p>

      <h6 className="footer-text--desktop footer-text--heading">om sbar</h6>
      <p className="footer-text--desktop">
        Detta är ett initiativ av vård och omsorgsarbetare för vård och
        omsorgsarbetare. Vi vill dela erfarenheter och idéer med varandra om vår
        arbetssituation och kämpa för en värdig vård för alla som inte gör oss
        sjuka!
      </p>
    </div>
  </footer>
);
