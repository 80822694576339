import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { FormOfType } from "./forms";
import { PersonForm } from "./forms/personForm";
import { ContactForm } from "./forms/contactForm";
import { FormContext } from "../../contexts/form-context";
import { DeviceContext } from "../../contexts/device-context";
import { Loading } from "../loading";
import { ReactComponent as Done } from "../../assets/svg/done.svg";

const formTypes = [
  { type: "text", text: "skicka in text" },
  { type: "video", text: "skicka in video" },
  { type: "audio", text: "skicka in ljud" },
];

export const FormSent = () => (
  <FormContext.Consumer>
    {({ resetForm }) => (
      <div className="form-sent--container">
        <h3 className="form-sent--title">Tack!</h3>
        <div className="form-sent--img">
          <Done />
        </div>

        <div onClick={resetForm} className="form-sent--message">
          <Link to="/">Klicka här för att återgå till förstasidan</Link>
        </div>
      </div>
    )}
  </FormContext.Consumer>
);

const Info = () => (
  <>
    <h3 className="form--info--header">
      Information om behandling av personuppgifter
    </h3>
    <p className="form--info">
      När du kontaktar oss eller laddar upp videos på vår hemsida kan vi få
      tillgång till personuppgifter i form av bild, namn eller mejladress. Dessa
      kommer enbart att behandlas för det syfte och ändamål för vilket de
      samlades in, till exempel att via mejl ta kontakt i frågor som gäller ditt
      bidrag. Du kan närsomhelst begära att få dina uppgifter raderade från
      hemsidan.
    </p>
  </>
);

export const Contact = () => (
  <FormContext.Consumer>
    {({ submitForm, formType, videoUploaded, formSent }) => (
      <div className="form form--section">
        <h1 className="form--header">Engagera dig!</h1>
        <div className="form-content--column">
          <Info />
          {formSent ? (
            <FormSent />
          ) : (
            <form id="contactForm" onSubmit={submitForm}>
              <ContactForm />
              <button
                form="contactForm"
                type="submit"
                className={`button button-distance--top ${
                  (formType === "video" && videoUploaded) ||
                  formType !== "video"
                    ? "button--filled"
                    : ""
                }`}
              >
                SKICKA
              </button>
            </form>
          )}
        </div>
      </div>
    )}
  </FormContext.Consumer>
);

const Form = ({ activeType }) => (
  <DeviceContext.Consumer>
    {(deviceType) => (
      <FormContext.Consumer>
        {({ submitForm, formType, isUploading }) => (
          <div className="form-content--container">
            {isUploading && <Loading />}
            <div className="form-content--column">
              {((formType && deviceType !== "mobile") ||
                (formType === activeType && deviceType === "mobile")) && (
                <>
                  <Info />
                  <form id="mainForm" onSubmit={submitForm} />
                  <div>
                    <FormOfType type={formType} />
                    <PersonForm />
                    <button
                      form="mainForm"
                      type="submit"
                      disabled={isUploading}
                      className="button button--filled"
                    >
                      SKICKA
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </FormContext.Consumer>
    )}
  </DeviceContext.Consumer>
);

export const Send = () => {
  const deviceType = useContext(DeviceContext);
  const { formType, setFormType, formSent, resetForm } = useContext(
    FormContext
  );

  const handleMobileClick = (type) => {
    setFormType(type);

    setTimeout(() => {
      const button = document
        .querySelector(`#form-button-${type}`)
        .getBoundingClientRect();
      const header = document.querySelector("header");
      const topOffset = button.top + window.pageYOffset - header.clientHeight;

      window.scrollTo({ left: 0, top: topOffset, behavior: "smooth" });
    }, 0);
  };

  return (
    <div className="form form--section">
      <h1 className="form--header">SBAR-Formulär</h1>

      {deviceType === "mobile" ? (
        <div className="form-container--mobile">
          {formTypes.map((t, i) => (
            <div key={i} className="form-content--mobile">
              <button
                onClick={() => {
                  handleMobileClick(t.type);
                  resetForm();
                }}
                id={`form-button-${t.type}`}
                className={`form-control--button button ${
                  deviceType === "mobile" ? "button--full" : ""
                } ${t.type === formType ? "button--filled" : ""}`}
              >
                {t.text}
              </button>
              {formSent && t.type === formType ? (
                <FormSent />
              ) : (
                <Form activeType={t.type} />
              )}
            </div>
          ))}
        </div>
      ) : (
        <>
          <div className="form-control-container--desktop">
            {formTypes.map((t, i) => (
              <button
                key={i}
                onClick={() => {
                  setFormType(t.type);
                  resetForm();
                }}
                className={`form-control--button button ${
                  deviceType === "mobile" ? "button--full" : ""
                } ${t.type === formType ? "button--filled" : ""}`}
              >
                {t.text}
              </button>
            ))}
          </div>

          {formSent ? <FormSent /> : <Form />}
        </>
      )}
    </div>
  );
};
