import axios from "axios";

export const createEntry = async (formdata) =>
  axios({
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formdata,
    url: `${process.env.REACT_APP_BACKEND_URL}/v1/entries`,
  });
