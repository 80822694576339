import React, { useContext } from "react";
import { FormContext } from "../../../contexts/form-context";

export const PersonForm = () => {
  const { formState, dispatch } = useContext(FormContext);

  return (
    <>
      <div className="form-person--linebreak" />
      {Object.keys(formState.person).map((key, i) => {
        if (formState.person[key].type === "checkbox") {
          return (
            <div
              key={i}
              className="form--container form-person--input form-person--container"
            >
              <input
                className="form--input form-person--checkbox"
                onChange={({ target: { value } }) =>
                  dispatch({ value, key, type: "person" })
                }
                id={key}
                form="mainForm"
                type={formState.person[key].type}
                value={formState.person[key].value}
                required={formState.person[key].required}
              />
              <label className="form-person--checkbox--label" id={i}>
                <span>
                  Jag godkänner{" "}
                  <a
                    href="https://www.sac.se/it/Dataskyddspolicy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    villkoren
                  </a>
                </span>
              </label>
            </div>
          );
        }

        return (
          <div key={i} className="form--container form-person--input">
            <input
              className="form--input "
              onChange={({ target: { value } }) =>
                dispatch({ value, key, type: "person" })
              }
              placeholder={formState.person[key].placeholder}
              type={formState.person[key].type}
              value={formState.person[key].value}
              required={formState.person[key].required}
            />
          </div>
        );
      })}
    </>
  );
};
