import React, { useState, useEffect, createContext } from "react";

const getDeviceType = (innerWidth) => {
  switch (true) {
    case innerWidth <= 480:
      return "mobile";
    case innerWidth > 480 && innerWidth < 769:
      return "tablet";
    case innerWidth > 769 && innerWidth < 1025:
      return "small-screen";
    case innerWidth > 1025 && innerWidth < 1201:
      return "medium-screen";
    default:
      return "big-screen";
  }
};

export const DeviceContext = createContext();

export const DeviceContextProvider = ({ children }) => {
  const [deviceType, setDeviceType] = useState("");

  const handleWindowSizeChange = () => {
    setDeviceType(getDeviceType(window.innerWidth));
  };
  useEffect(() => {
    setDeviceType(getDeviceType(window.innerWidth));

    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <DeviceContext.Provider value={deviceType}>
      {children}
    </DeviceContext.Provider>
  );
};
