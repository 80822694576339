import React from "react";
import { Send } from "../components/form";
import { Intro } from "../components/intro";
import { SendInText } from "../components/intro/sendInText";

export const SendView = () => (
  <>
    <Intro>
      <SendInText />
    </Intro>
    <Send />
  </>
);
