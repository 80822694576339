import React from "react";
import { Header, Footer } from "./layout";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { StoriesContextProvider } from "./contexts/stories-context";
import { ActiveStoryContextProvider } from "./contexts/active-story-context";
import { FormContextProvider } from "./contexts/form-context";
import { DeviceContextProvider } from "./contexts";
import { RestoreScrollOnNavigation } from "./components/restoreScroll";
import { LandingView } from "./views/landing";
import { SendView } from "./views/send";
import { ContactView } from "./views/contact";
import { AboutView } from "./views/about";
import { NursingConferenceView } from "./views/nursingConference";

export const App = () => (
  <Router>
    <RestoreScrollOnNavigation />
    <DeviceContextProvider>
      <StoriesContextProvider>
        <ActiveStoryContextProvider>
          <FormContextProvider>
            <Header />
            <main>
              {
                <Switch>
                  <Route path="/skicka-in">
                    <SendView />
                  </Route>
                  <Route path="/kontakt">
                    <ContactView />
                  </Route>
                  <Route path="/om">
                    <AboutView />
                  </Route>
                  <Route path="/omvardnadskonferensen">
                    <NursingConferenceView />
                  </Route>
                  <Route path="/">
                    <LandingView />
                  </Route>
                </Switch>
              }
            </main>
            <Footer />
          </FormContextProvider>
        </ActiveStoryContextProvider>
      </StoriesContextProvider>
    </DeviceContextProvider>
  </Router>
);
