import React from "react";
import { ReactComponent as ExternalLinkIcon } from "../../assets/svg/sbar-link-icon.svg";

import { Link } from "react-router-dom";
import { FormContext } from "../../contexts/form-context";

const NavItems = [
  {
    name: "Skicka in SBAR",
    class: "navbar-link navbar-link--main",
    href: "/skicka-in",
  },
  {
    name: "Engagera dig!",
    class: "navbar-link navbar-link--main",
    href: "/kontakt",
  },
  { name: "Om SBAR", class: "navbar-link navbar-link--secondary", href: "/om" },
  {
    name: "Omvårdnadskonferensen",
    class: "navbar-link navbar-link--secondary navbar-link--small",
    href: "/omvardnadskonferensen",
  },
  {
    name: "SAC",
    class: "navbar-link navbar-link--external",
    href: "https://www.sac.se",
    external: true,
    icon: ExternalLinkIcon,
  },
];

export const Nav = ({ active, top, handleClick }) => (
  <FormContext.Consumer>
    {({ resetForm }) => (
      <nav
        className={`navbar ${top ? "navbar--top" : "navbar--bottom"} ${
          active ? "active" : "inactive"
        }`}
      >
        <ul className="navbar-list">
          {NavItems.map((item, idx) => {
            if (item?.external) {
              const Icon = item?.icon;
              return (
                <li key={idx} className={item.class}>
                  <a target="_blank" rel="noopener noreferrer" href={item.href}>
                    <span>{item.name}</span>
                    {item?.icon && (
                      <div className="navbar-link--external--icon">
                        <Icon />
                      </div>
                    )}
                  </a>
                </li>
              );
            }

            return (
              <li
                onClick={(e) => {
                  handleClick(e);
                  resetForm();
                }}
                key={idx}
                className={item.class}
              >
                <Link to={item.href}>{item.name}</Link>
              </li>
            );
          })}
        </ul>
      </nav>
    )}
  </FormContext.Consumer>
);
