import React from "react";
import { TextForm } from "./textForm";
import { VideoForm } from "./videoForm";
import { AudioForm } from "./audioForm";

export const FormOfType = ({ type }) => {
  switch (type) {
    case "text":
      return <TextForm />;
    case "video":
      return <VideoForm />;
    case "audio":
      return <AudioForm />;
    case "finshed":
      return "done!";
    default:
      return null;
  }
};
