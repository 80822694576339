import React from "react";

export const AboutText = () => (
  <>
    <p className="intro-text">
      SBAR (Situation / Bakgrund / Aktuellt / Rekommendation) är en
      kommunikationsmodell som används i vården för att överlämna information om
      en patient på ett snabbt och effektivt sätt. Vi vänder om SBAR för att
      spegla oss själva och förstå den situation vi är i nu. Vi gör SBAR till
      vårt verktyg, för att med det uttrycka vad vi som arbetar i vården tycker
      är fel och vad vi behöver göra åt det. Projektet är finansierat och
      utformat av SAC Syndikalisterna för <em>alla</em> arbetare, oavsett
      facklig hemvist.
    </p>

    <p className="intro-text">
      Vårdarbete och omsorgsarbete har alltid förknippats med kvinnor och
      kvinnors obetalda arbete i hemmet, men också kvinnors underbetalda
      lönearbete. Även om det idag finns vårdarbetare av alla kön, gör den
      patriarkala nedvärderingen av kvinnor och arbete som kodas som kvinnligt
      att vård- och omsorgsarbete fortfarande nedvärderas. Omsorgsarbete handlar
      om att skapa återhämtning och välmående. Det är helt grundläggande för att
      samhället ska fungera. Ändå plockas vården och patientsäkerheten stadigt
      isär för att vårdbolagen ska tjäna mer och mer pengar på vår hälsa och
      ohälsa. Att vården inte prioriteras, och att vård- och omsorgsarbetares
      hälsa och behov av återhämtning inte respekteras tyder på en total
      ignorans inför nödvändigheten i arbetet. Här behövs, lika mycket som ett
      klassperspektiv - ett feministiskt perspektiv. Vi behöver förgöra
      föreställningar om vård och omsorg som en outtömlig naturresurs att
      exploatera. Vår vård behöver omsorg för att kunna fortgå och inte
      förbrukas. Att uppvärdera det arbetet handlar inte om några applåder på
      balkongen, utan om att vård- och omsorgsarbetares löner, arbetsmiljö och
      villkor förbättras. Utan det - ingen vård.
    </p>
  </>
);
