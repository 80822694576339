import React from "react";
import { Intro } from "../components/intro";
import { LandingText } from "../components/intro/landingText";
import { Stories } from "../components/stories";

export const LandingView = () => (
  <>
    <Intro position="left">
      <LandingText />
    </Intro>
    <div className="breakline" />
    <Stories />
  </>
);
