import React, { useEffect, useState, useCallback, useContext } from "react";
import { getAll } from "../../api/methods/getAll";
import { DeviceContext } from "../device-context";

export const StoriesContext = React.createContext({
  stories: [],
  isLoading: true,
});

const inGroup = {
  mobile: 1,
  tablet: 2,
  "small-screen": 2,
  "medium-screen": 4,
  "big-screen": 4,
};

export const getNumberInGroup = (deviceType) => inGroup[deviceType];

const groupStories = (stories = [], deviceType) => {
  let groupsOfStories = [];
  const inSubgroup = getNumberInGroup(deviceType);
  let remaindersGroup = [];

  for (const [i] of stories.entries()) {
    const lengthIdx = i + 1;

    if (inSubgroup === 1) {
      groupsOfStories = [...groupsOfStories, [stories[i]]];
    } else {
      if (i !== 0 && lengthIdx % inSubgroup === 0) {
        const startIdx = lengthIdx - inSubgroup;
        groupsOfStories = [
          ...groupsOfStories,
          [...stories.slice(startIdx, lengthIdx)],
        ];

        remaindersGroup = [...stories.slice(i, stories.length - 1)];
      }

      if (i === stories.length - 1) {
        groupsOfStories = [...groupsOfStories, [...remaindersGroup]];

        if (stories.length < inSubgroup) {
          groupsOfStories = [[...stories]];
        }
      }
    }
  }
  return groupsOfStories;
};

export const StoriesContextProvider = ({ children }) => {
  const deviceType = useContext(DeviceContext);
  const [stories, setStories] = useState([[]]);
  const [data, setData] = useState([]);
  const [isLoading, setIsloading] = useState(true);

  const getData = useCallback(async () => {
    try {
      const res = await getAll();

      if (res.status === 200) {
        setData(res.data);
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (data.length > 0 && deviceType) {
      setStories(groupStories(data, deviceType));
    }
  }, [deviceType, data]);

  useEffect(() => {
    if (stories && stories.length > 0) {
      setIsloading(false);
    }
  }, [stories]);

  return (
    <StoriesContext.Provider
      value={{ stories, isLoading, getStories: getData }}
    >
      {children}
    </StoriesContext.Provider>
  );
};
