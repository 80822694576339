import React from "react";

export const SendInText = () => (
  <>
    <p className="intro-text">
      För att spela in en SBAR kan du som enklast prata in direkt i din egen
      mobilkamera. Tänk då i huvudsak på två saker; ljud och ljus. Om du har ett
      head-set kan det vara fördelaktigt att använda den mikrofonen för bättre
      ljudupptagning och tänk på att inte filma i motljus.
    </p>
    <p className="intro-text">
      Om du vill vara anonym går det så klart alldeles utmärkt. Allt du i så
      fall behöver göra är att inte rikta kameran mot dig själv. Filma en fasad
      eller utsidan av din arbetsplats eller liknande istället för dig själv när
      du läser in din SBAR-rapport. Det går också bra att bidra med text för att
      vara anonym.
    </p>
    <p className="intro-text">
      Om du vill göra en film om din arbetssituation i ett annat format än SBAR
      så går det så klart bra. Huvudsaken är att just din åsikt hörs!
    </p>

    <p className="intro-text">Dela din berättelse här!</p>
  </>
);
