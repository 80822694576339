import React from "react";

export const NursingConference = () => (
  <>
    <h3 className="intro-header intro-header--small">Omvårdnadskonferensen</h3>
    <p className="intro-text">
      Omvårdnadskonferensen samlar vårdarbetare och fackföreningar inom vård och
      omsorg. Den kommer att hållas under sensommaren 2021.
    </p>

    <p className="intro-text">
      Detta projekt stöds av SAC Syndikalisterna och är en del av arbetet inför
      en kommande omvårdnadskonferens. I höst bjuder SAC in brett till en
      konferens om tvärfacklig mobilisering inom vården. Alla som arbetar inom
      vården är välkomna att delta i konferensen oavsett facklig tillhörighet.
    </p>
    <p className="intro-text">
      Vi bjuder in till denna konferens då våra medlemmar inom vården
      identifierat stora problem. Syftet är att få igång en mobiliseringsprocess
      inom vården, av vårdpersonal för vårdpersonal. SAC hävdar ingen äganderätt
      i detta projekt utan vill endast ge våra arbetskamrater verktyg att driva
      egna kampanjer med, oavsett vilken fackförening de tillhör.
    </p>
    <p className="intro-text">
      Om SAC: Sveriges Arbetares Centralorganisation (SAC) har funnits sedan
      1910. Vi är partipolitiskt obundna och finns som ett alternativ till den
      socialdemokratiska fackföreningsrörelsen. För mer info:{" "}
      <a href="https://www.sac.se" target="_blank" rel="noopener noreferrer">
        (www.sac.se)
      </a>
    </p>
  </>
);
