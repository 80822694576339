import React, { createContext, useReducer, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import deepClone from "rfdc/default";
import { createEntry } from "../../api/methods/create";
import { createContact } from "../../api/methods/contact";
import { createVimeoVideo } from "../../api/methods/vimeoVideoUpload";
import model from "./model.json";

export const FormContext = createContext();

const formReducer = (prevState, { value, key, type, action = "update" }) => {
  switch (action) {
    case "update":
      const updatedElement = { ...prevState[type][key] };
      updatedElement.value = value;
      const newState = deepClone(prevState);
      newState[type][key] = updatedElement;
      return newState;
    case "reset":
      return model;
    default:
      return prevState;
  }
};

export const FormContextProvider = ({ children }) => {
  const [formType, setFormType] = useState("");
  const [formFile, setFormFile] = useState(null);
  const [videoUploaded, setVideoUploaded] = useState(false);
  const [videoReadyToUpload, setVideoReadyToUpload] = useState(false);
  const [videoUploadLink, setVideoUploadLink] = useState("");
  const [videoViewLink, setVideoViewLink] = useState("");
  const [formState, dispatch] = useReducer(formReducer, model);
  const [formSent, setFormSent] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const { search } = useLocation();
  const { replace } = useHistory();

  const resetForm = () => {
    setVideoUploaded(false);
    setFormSent(false);
    setVideoReadyToUpload(false);
    setFormFile(null);
    dispatch({ action: "reset" });
    setVideoUploadLink("");
    setVideoViewLink("");
    setIsUploading(false);
  };

  const onFileChange = async (e) => {
    e.preventDefault();

    if (formType === "video") {
      const uploadLink = await createVimeoVideo(
        e.target.files[0].size,
        e.target.files[0].name,
        window.location.href
      );
      setVideoUploadLink(uploadLink);
    }
    if (formType === "audio") {
      setFormFile(e.target.files[0]);
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();

    const form = new FormData();

    const isContact = formType === "contact";
    const isText = formType === "text";
    const isVideo = formType === "video";
    const isAudio = formType === "audio";

    if (formFile && isAudio) {
      form.append("media_file", formFile);
      form.append("media_type", "audio");
    }

    if (videoViewLink && isVideo) {
      form.append("media_url", videoViewLink);
      form.append("media_type", "video");
    }

    if (!isContact) {
      Object.keys(formState.person).forEach((k) => {
        form.append(k, formState.person[k].value);
      });
    }

    if (isContact) {
      Object.keys(formState.contact).forEach((k) => {
        form.append(k, formState.contact[k].value);
      });
    }

    if (isText) {
      form.append("media_type", "text");
      Object.keys(formState[formType]).forEach((k) => {
        form.append(k, formState[formType][k].value);
      });
    }

    const sendForm = isContact ? createContact : createEntry;
    try {
      const response = await sendForm(form);
      if (response.status >= 200 && response.status < 300) {
        resetForm();
        setFormSent(true);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (formType === "video" && videoUploadLink) {
      setVideoReadyToUpload(true);
    }
  }, [formType, videoUploadLink]);

  useEffect(() => {
    const decodedQuery = decodeURIComponent(search);
    if (search) {
      if (decodedQuery.includes("video_uri")) {
        setFormType("video");
        setVideoUploaded(true);
        replace({ search: "" });
        setIsUploading(false);
        setVideoViewLink(
          `https://vimeo.com/${decodedQuery.replace("?video_uri=/videos/", "")}`
        );
      }
    }
  }, [search, replace]);

  return (
    <FormContext.Provider
      value={{
        formType,
        setFormType,
        formState,
        dispatch,
        submitForm,
        onFileChange,
        videoReadyToUpload,
        videoUploaded,
        videoUploadLink,
        formSent,
        resetForm,
        isUploading,
        setIsUploading,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};
