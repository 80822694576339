import React, { useContext } from "react";
import { FormContext } from "../../../contexts/form-context";

export const TextForm = () => {
  const { formType, formState, dispatch } = useContext(FormContext);

  return (
    <>
      {Object.keys(formState[formType]).map((key, i) => (
        <div key={i} className="form--container">
          <label className="form--label" htmlFor={key} key={key}>
            {formState[formType][key].label}
          </label>
          <textarea
            className="form--input form-text--textarea"
            onChange={({ target: { value } }) =>
              dispatch({ value, key, type: formType })
            }
            rows="5"
            id={key}
            form="mainForm"
            placeholder={formState[formType][key].placeholder}
            type={formState[formType][key].type}
            value={formState[formType][key].value}
            required={formState[formType][key].required}
          />
        </div>
      ))}
    </>
  );
};
