import axios from "axios";

const getUploadLink = (response) => response.data.upload.upload_link;

export const createVimeoVideo = async (size, name, redirect_url) => {
  try {
    const res = await axios({
      method: "POST",
      headers: {
        Authorization: `bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_VIMEO_URL}/me/videos`,
      data: {
        upload: {
          approach: "post",
          size: `${size}`,
          redirect_url,
        },
        name: name,
        privacy: { view: "nobody" },
      },
    });

    return getUploadLink(res);
  } catch (e) {
    console.error(e, e.stack);
  }
};
