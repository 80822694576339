import React from "react";
import { FormContext } from "../../../contexts/form-context";

export const AudioForm = () => (
  <FormContext.Consumer>
    {({ onFileChange }) => (
      <div className="form-upload--container">
        <div className="form--container">
          <label className="form--label" htmlFor="audio-image-upload">
            Ladda upp audio *
          </label>
          <div className="form-upload--row">
            <input
              className="form--input form-upload-field form-upload-field--audio"
              id="audio-image-upload"
              form="mainForm"
              type="file"
              accept="audio/*"
              required
              onChange={onFileChange}
            />
          </div>
        </div>
      </div>
    )}
  </FormContext.Consumer>
);
