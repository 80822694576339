import React, { useContext, useEffect } from "react";
import { FormContext } from "../../../contexts/form-context";

export const ContactForm = () => {
  const { formState, dispatch, setFormType } = useContext(FormContext);

  useEffect(() => {
    setFormType("contact");
  }, [setFormType]);

  return (
    <>
      {Object.keys(formState.contact).map((key, i) => (
        <div key={i} className="form--container">
          <label className="form--label" htmlFor={key} key={key}>
            {formState.contact[key].label}
          </label>
          <input
            className="form--input"
            form="contactForm"
            onChange={({ target: { value } }) =>
              dispatch({ value, key, type: "contact" })
            }
            id={key}
            type={formState.contact[key].type}
            value={formState.contact[key].value}
            required={formState.contact[key].required}
          />
        </div>
      ))}
    </>
  );
};
