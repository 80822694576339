import React, { useContext } from "react";
import { FormContext } from "../../../contexts/form-context";

const VideoView = () => (
  <div>
    <h3 className="form-upload--title">
      Din video kommer att laddas upp efter att du skickat in formuläret.
    </h3>
  </div>
);

export const VideoForm = () => {
  const {
    onFileChange,
    videoReadyToUpload,
    videoUploadLink,
    videoUploaded,
    setIsUploading,
  } = useContext(FormContext);

  if (videoUploaded) {
    return <VideoView />;
  }

  return (
    <form
      method="POST"
      id="videoForm"
      action={videoUploadLink}
      encType="multipart/form-data"
      className="form-upload--container"
    >
      <div className="form--container">
        <label className="form--label" htmlFor="video-image-upload">
          Ladda upp video-fil
        </label>
        <div className="form-upload--row">
          <input
            className="form--input form-upload-field form-upload-field--video"
            id="video-image-upload"
            name="file_data"
            type="file"
            accept="video/*"
            form="videoForm"
            required
            onChange={onFileChange}
          />

          <button
            form="videoForm"
            className={`form-upload--button ${
              videoReadyToUpload ? "button--filled" : ""
            } button`}
            disabled={!videoReadyToUpload}
            onClick={() => setIsUploading(true)}
            type="submit"
          >
            Ladda upp
          </button>
        </div>
      </div>
    </form>
  );
};
