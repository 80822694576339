import React from "react";

export const LandingText = () => (
  <>
    <h1 className="intro-header intro-header--left">
      Situation / Bakgrund /
      <br />
      Aktuellt / Rekommendation
    </h1>

    <p className="intro-text">
      SBAR är en modell som används i vården för att snabbt rapportera om en
      patient. Vi gör SBARs till arbetarnas verktyg i kampen för en bättre vård!
    </p>
    <p className="intro-text">
      Det finns en utspridd frustration och oro över hur sjukvården och omsorgen
      organiseras. Vad är vi egentligen med om? Vad har skapat denna situation?
      Vad är det som händer? Hur går vi vidare?
    </p>
    <p className="intro-text">BRYT TYSTNADEN OCH TALA UT!!!</p>
  </>
);
