import React, { useState } from "react";

export const ActiveStoryContext = React.createContext({});

export const ActiveStoryContextProvider = ({ children }) => {
  const [activeStory, setActiveStory] = useState(null);

  return (
    <ActiveStoryContext.Provider value={{ activeStory, setActiveStory }}>
      {children}
    </ActiveStoryContext.Provider>
  );
};
