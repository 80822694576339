import React from "react";
import { Intro } from "../components/intro";
import { AboutText } from "../components/intro/aboutText";
import { DeviceContext } from "../contexts/device-context";

export const AboutView = () => (
  <DeviceContext.Consumer>
    {(deviceType) => (
      <Intro position={deviceType === "mobile" ? "left" : "center"}>
        <AboutText />
      </Intro>
    )}
  </DeviceContext.Consumer>
);
